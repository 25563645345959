import React from 'react';
import GoBackLink from '../components/go-back-link';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { INewsArticlePage } from '../types/heartcore.types';
import { dateFormatter, htmlRaw } from '../utils/utils';

const NewsArticlePage = ({pageContext}:PageData<INewsArticlePage>) => {
	const { meta, parent, modules, headline, introText, date, _updateDate } = useContent(pageContext);

	return (
		<>
			<Meta {...meta} />
			<div className='box-wide news-article-page'>
				<GoBackLink parent={parent}></GoBackLink>
				<div className='news-article-page__content'>
					<div className='news-article-page__content__date'>{dateFormatter(date || _updateDate)}</div>
					<h1 className='news-article-page__content__headline headline-with-html' dangerouslySetInnerHTML={htmlRaw(headline)}></h1>
					<p className='news-article-page__content__intro-text'>{introText}</p>
				</div>
			</div>
			<ModulesController modules={modules} />
		</>
	);
};

export default NewsArticlePage;