import React, { useContext } from 'react';
import LokaltogContext from '../context/context';
import LinkWithPageTransition from './link-with-page-transition';
import ArrowIcon from './svg/arrow-icon';

type GoBackLink = {
	parent: {
		url: string;
		name: string;
	}
}

const GoBackLink = ({ parent }: GoBackLink) => {
	if(!parent || !parent.name) { return <></>; }
	return <></>;
	const lokaltogContext = useContext(LokaltogContext);

	return (
		<div className='link--go-back'>
			<div className='link-icon'><ArrowIcon /></div>
			<LinkWithPageTransition url={parent.url} classes='link-with-underline'>{lokaltogContext.tr('Global.GoBackTo')} {parent.name}</LinkWithPageTransition>
		</div>
	);
};

export default GoBackLink;
